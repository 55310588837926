import React from "react";
import {
  ref,
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
} from "reactivue";
import { Typography } from "@material-ui/core";
import d from "@dynamic";

const Button = d(() =>
  import("@material-ui/core/Button").then((x) => x.default)
);

const Test = d(() => import("../components/Test").then((x) => x.default));

type Props = { value: number };

const MyCounter = defineComponent(
  // setup function in Vue
  (props: Props) => {
    const counter = ref(props.value);
    const doubled = computed(() => counter.value * 2);
    onMounted(() => {
      console.log("mounted game");
    });
    onUnmounted(() => {
      console.log("unmounted game 3");
    });
    const inc = () => (counter.value += 1);
    return { counter, doubled, inc };
  },
  // functional component in React
  ({ counter, doubled, inc }) => {
    // you can still use other React hooks
    return (
      <div>
        <div>
          {counter} x 2 = {doubled}
        </div>
        <Button variant="contained" color="primary" onClick={inc}>
          +
        </Button>
      </div>
    );
  }
);

export default function Home() {
  return (
    <div>
      <h2>Testing SSR Setup</h2>
      <MyCounter key={"counter"} value={2}></MyCounter>
      <Test></Test>
    </div>
  );
}

export { Layout } from "../layout/main";
